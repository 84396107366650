<script setup lang="ts">
    import { useMainStore } from '~~/stores/main';
    import useAssets from '~~/composables/useAssets';

    const mainStore = useMainStore();
    const assets = useAssets();

</script>

<template>
    <NuxtLink to="/" aria-label="home" rel="next" class="flex items-center flex-shrink-0 text-clive-black" @click="mainStore.saveRoute('index')">
        <img :src="assets.clive_logo" alt="ConsumerLive logo" class="object-cover h-8 xl:h-[51px] mr-3" height="32">
        <div class="flex items-center flex-col leading-4">
            <span class="text-[18px] xl:text-4xl font-black text-[#373B64] font-['Satoshi-black']">ConsumerLive®</span>
            <span class="uppercase text-[6px] xl:text-xs tracking-[0.12em]">Feedback Management Solutions</span>
        </div>
    </NuxtLink>
</template>