<script setup lang="ts">
    import { useMainStore } from '~~/stores/main';
    import { useLanguageStore } from '~~/stores/language';
    import useAssets from '~~/composables/useAssets';

    const mainStore = useMainStore();
    const languageStore = useLanguageStore();
    const assets = useAssets();

    const lang = computed(() => {
        return languageStore.language as keyof Lang;
    }) ;
    const activeRoute = computed(() => {
		return mainStore.activeRoute;
	});
        
</script>

<template>
    <footer class="bg-clive-bg m-auto flex flex-col place-items-center w-full text-clive-black py-8">
        <div class="px-6 xl:px-0 lg:max-w-[1024px] xl:max-w-[1214px] m-auto w-full flex gap-3 items-center flex-col lg:flex-row justify-between">
            <div class="flex flex-col align-center justify-center">
                <AtomConsumerLiveLogo />
                <span class="hidden lg:block text-center text-[12px] lg:text-left lg:text-[13.5px] mt-2" v-html="languageStore.translations.footer.caption[lang]"/>
            </div>
            <span class="lg:hidden text-center text-[12px] sm:text-left sm:text-[13.5px] mt-2 lg:pl-6" v-html="languageStore.translations.footer.caption[lang]"/>
            
            <div class="w-full flex justify-center lg:w-auto gap-6 text-sm mt-6 sm:mt-0 text-blue-link">
                <!-- TODO: add proper routes -->
                <NuxtLink v-if="activeRoute !== 'legal-terms'" to="/legal-terms" @click="mainStore.saveRoute('legal-terms')">
                    {{ languageStore.translations.footer.links.legal[lang] }}
                </NuxtLink>
                <NuxtLink v-if="activeRoute !== 'privacy'" to="/privacy" @click="mainStore.saveRoute('privacy')">
                    {{ languageStore.translations.footer.links.confidentiality[lang ]}}
                </NuxtLink>
            </div>
        </div>
        <div class="lg:max-w-[1024px] xl:max-w-[1214px] w-full flex justify-center mt-4 border-t-2 py-6">
            <NuxtLink to="https://colorado-groupe.com/" target="_blank">
                <img :src="assets.colorado_logo" alt="Colorado Groupe Logo" class="h-8 object-cover" height="32">
            </NuxtLink>
        </div>
    </footer>
</template>