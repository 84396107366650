<script setup lang="ts">
import AppFooter from '~~/components/AppFooter.vue';
import { useMainStore } from '~~/stores/main';
import { useLanguageStore } from '~~/stores/language';
const mainStore = useMainStore();
const languageStore = useLanguageStore();

    onMounted(() => {
        mainStore.isNarrow = window.innerWidth < 500;
        mainStore.isMobile = window.innerWidth <= 820;
        mainStore.isTablet = window.innerWidth > 820 && window.innerWidth <= 1024;
        
        let browserLang = "fr" as string | null;

        if(process.client) {
            if(!!localStorage.getItem("lang")) {
                browserLang = localStorage.getItem("lang");
            }else{
                browserLang = window.navigator.language;
            }
        }else{
            browserLang = window.navigator.language;
        }

        switch (true) {
            case !!browserLang && browserLang.includes("fr"):
                languageStore.language = 'fr';
                break;
        
            case !!browserLang && browserLang.includes('en'):
                languageStore.language = 'en';
                break;

            default:
                languageStore.language = 'fr';
                break;
        }
        
        window.addEventListener("resize", (e) => {
            mainStore.$patch({
                isNarrow: (e.target as Window).innerWidth <= 500,
                isMobile: (e.target as Window).innerWidth <= 820,
                isTablet: (e.target as Window).innerWidth > 820 && (e.target as Window).innerWidth <= 1024
            });
        })
    });

    const lang = computed(() => {
        return languageStore.language as keyof Lang;
    });

    useHead({
        htmlAttrs: {
            lang
        }
    });

</script>

<template>
    <div>
        <NuxtLoadingIndicator color="#6376DD" />
        <!-- <a
        class="fixed left-1/2 -translate-x-1/2 transition p-3 px-6 mt-3 -translate-y-16 focus:translate-y-0 text-white bg-gradient-to-br from-purple-600 to-blue-500 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
        href="#main-content" style="z-index:60;"
        >
            {{ languageStore.skipNavigation[lang] }}
        </a>    -->
       <AppHeader/>
       <main id="main-content" class="z-0">
           <slot />
       </main>
        <AppFooter/>
    </div>
</template>

<style>
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
      margin-top: 1px;
      background: #eeeeee;
      border-radius: 100vw;
      border: 1px solid #cacaca;
  }
  ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 100vw;
  }
</style>