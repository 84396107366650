<script setup lang="ts">
	import { useLanguageStore } from '~~/stores/language';
	import { useMainStore } from '~~/stores/main';
	import { initDropdowns } from 'flowbite';
	
	const languageStore = useLanguageStore();
	const mainStore = useMainStore();
	const route = useRoute();
	const lang = computed(() => languageStore.language as keyof Lang);
	const scroll = ref(0);

	onMounted(() => {
		mainStore.saveRoute(route.name as unknown as string);
		initDropdowns();
		document.addEventListener("click", closeOnClickOutside);

		// Show gradual header box shadow on scroll
		const header = document.getElementById("app-header");
		window.addEventListener("scroll", () => {
			let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0 && scroll.value <= currentScroll){
				// Down
				scroll.value = currentScroll;
			}else{
				// Up
				scroll.value = currentScroll;
			}
			if(header) {
				header.style.boxShadow = `0 4px 22px rgba(0,0,0,0.${scroll.value > 110 ? 110 : scroll.value < 50 ? 0 : scroll.value/10})`
			}
		}, {passive: true});
		const recaptchaTag = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
		if(recaptchaTag) {
			if(activeRoute.value === "contact") {
				recaptchaTag.style.visibility = "visible";
			}else {
				recaptchaTag.style.visibility = "hidden";
			}
		}
		loadCookieConsentElement();
	});

	function loadCookieConsentElement() {
		const cookieConsentElement = document.getElementById("termsfeed-com---nb");
		if(cookieConsentElement) {
			setTimeout(() => {
				const title = document.getElementById("cc-nb-title");
				const content = document.getElementById("cc-nb-text");
				if(title) {
					title.innerHTML = languageStore.cookieconsent.title[lang.value];
				}
				if(content) {
					content.innerHTML = languageStore.cookieconsent.content[lang.value];
				}
				const cookieConsentWrapper = document.getElementById("termsfeed-com---nb");
				const buttonAccept = document.getElementsByClassName("cc-nb-okagree")[0];
				if(buttonAccept) {
					buttonAccept.innerHTML = languageStore.cookieconsent.buttons.accept[lang.value];	
				}
				const buttonReject = document.getElementsByClassName("cc-nb-reject")[0];
				if(buttonReject) {
					buttonReject.innerHTML = languageStore.cookieconsent.buttons.reject[lang.value];
				}
				const buttonPreferences = document.getElementsByClassName("cc-nb-changep")[0];
				if(buttonPreferences) {
					buttonPreferences.innerHTML = languageStore.cookieconsent.buttons.preferences[lang.value];
				}
				if(cookieConsentWrapper) {
					cookieConsentWrapper.style.opacity = "1"
				}
			})
		}
	}

	onUnmounted(() => {
		document.removeEventListener("click", closeOnClickOutside);
	});

	const languageOptions = computed<LanguageOption[]>(() => languageStore.languageOptions);

	function selectLanguage(language: string) {
		languageStore.language = language;
		if(process.client) {
		localStorage.setItem("lang", language);
		mainStore.appStep += 1;
		loadCookieConsentElement();
		}
	}

	const showMenu = ref(false);

	function closeOnClickOutside(e:Event) {
		if(!(e.target as Element).closest('.mobile-menu')) {
		showMenu.value = false;
		}
	}

	const activeRoute = computed(() => {
		return mainStore.activeRoute;
	});

	watch(activeRoute, (newRoute) => {
		const recaptchaTag = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
			if([newRoute, activeRoute.value].includes("contact")) {
				recaptchaTag.style.visibility = "visible";
			}else {
				recaptchaTag.style.visibility = "hidden";
			}
	});

	</script>

	<template>
	<nav id="app-header" class="fixed w-full top-0 z-50 bg-clive-bg py-4 md:py-6 md:pr-2" role="navigation">

		<div class="xl:max-w-[1214px] m-auto flex items-center justify-between flex-wrap">
			<AtomConsumerLiveLogo class="pl-6 xl:pl-0"/>

			<div class="desktop-menu flex align-center justify-center place-items-center w-auto gap-[2px] lg:gap-3">
				<NuxtLink 
					v-for="menuItem in mainStore.menu" 
					:to="menuItem.route"
					:rel="menuItem.rel"
					@click="mainStore.saveRoute(menuItem.name)"
					:class="`inline-block md:mt-0 ${mainStore.activeRoute === menuItem.name ? 'text-clive-blue' : 'text-clive-black'} hover:text-clive-blue mr-4 text-lg flex align-center justify-center place-items-center`"
					:aria-label="menuItem.name"
				>
					<span v-if="menuItem.type === 'normal-link'" class="font-bold hidden lg:inline-block">
						{{ languageStore.translations.nav[menuItem.translation as keyof object][lang] }}
					</span>

					<AtomButton v-if="menuItem.type === 'button' && activeRoute !== menuItem.name" size="base" padding="py-2" :content="languageStore.translations.nav[menuItem.translation as keyof object][lang]" :ariaLabel="menuItem.name" class="hidden lg:block" />
				
					<AtomButton v-if="menuItem.type === 'button' && activeRoute !== menuItem.name" :ariaLabel="menuItem.name" class="lg:hidden" height="h-[32px]" width="w-[32px] px-0" style="padding:0 !important">
						<CustomIconEnvelope stroke="white"/>
					</AtomButton>
				</NuxtLink>

				<NuxtLink to="/" v-if="activeRoute !== 'index'" class="hidden lg:inline-block lg:mt-0 hover:text-clive-blue mr-4 text-lg" @click="mainStore.saveRoute('index')" aria-label="home">
					{{ languageStore.translations.nav["index"][lang] }}
				</NuxtLink>
				<NuxtLink to="/" v-if="activeRoute !== 'index'" aria-label="home" class="lg:hidden mr-4 text-lg" @click="mainStore.saveRoute('index')">
					<CustomIconHome/>
				</NuxtLink>

				<AtomDropdown :options="languageOptions" :callback="selectLanguage" icon="CustomIconPlanet" :selectedReference="lang"/>
			</div>
		</div>
	</nav>
</template>

<style scoped>
#app-header {
  transition: box-shadow 0.6s ease-in-out;
  position: fixed;
}
.desktop-menu a {
  margin: 0 auto;
  padding: 0 6px;
}
</style>